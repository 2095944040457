body{
  font-family: "Open Sans", sans-serif !important;
}
.header-div-1 {
  margin-top:0px;
  margin-bottom:0px;
  padding-top:0.2rem;
  padding-bottom:0.2rem;
}
.header-div-2 {
  margin-top:0px;
  margin-bottom:0px;
  padding-top:0.2rem;
  padding-right:20px;
  padding-bottom:0.2rem;
  padding-left:20px;
}
.header-div-3 {
  padding-top:0px;
  padding-right:0px;
  padding-bottom:0px;
  padding-left:0px;
}
.header-div-4 {
  margin-top:0;
  margin-bottom: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.header-div-5 {
  font-size: 14px;
}
.header-div-6 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.header-div-7 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.header-div-8 {
  color: #fff;
}
.header-div-9 {
  border-style: none;
  border-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0;
  padding-bottom: 0;
}
.header-div-10 {
  border-top-style: none;
  border-top-width: 0px;
  border-right-style: none;
  border-right-width: 0px;
  border-bottom-color: var(--sc--preset--color--black);
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-left-style: none;
  border-left-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: var(--sc--preset--spacing--40);
  padding-right: 20px;
  padding-bottom: var(--sc--preset--spacing--40);
  padding-left: 20px;
}
.header-div-11 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  flex-basis: 50%;
}
.header-div-12 {
  border-style: none;
  border-width: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  flex-basis: 50%;
}
img.sc-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.sc-group {
  box-sizing: border-box;
}

:where(.sc-group.has-background) {
  padding: 1.25em 2.375em;
}

.sc-image img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

.sc-image img,
.sc-image.has-custom-border img {
  box-sizing: border-box;
}

.sc-image.aligncenter {
  text-align: center;
}

.sc-image.alignfull img,
.sc-image.alignwide img {
  height: auto;
  width: 100%;
}

.sc-image .aligncenter,
.sc-image .alignleft,
.sc-image .alignright,
.sc-image.aligncenter,
.sc-image.alignleft,
.sc-image.alignright {
  display: table;
}

.sc-image .aligncenter > figcaption,
.sc-image .alignleft > figcaption,
.sc-image .alignright > figcaption,
.sc-image.aligncenter > figcaption,
.sc-image.alignleft > figcaption,
.sc-image.alignright > figcaption {
  caption-side: bottom;
  display: table-caption;
}

.sc-image .alignleft {
  float: left;
  margin: 0.5em 1em 0.5em 0;
}

.sc-image .alignright {
  float: right;
  margin: 0.5em 0 0.5em 1em;
}

.sc-image .aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.sc-image figcaption {
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.sc-image .is-style-rounded img,
.sc-image.is-style-circle-mask img,
.sc-image.is-style-rounded img {
  border-radius: 9999px;
}

@supports ((-webkit-mask-image: none) or (mask-image: none)) or
  (-webkit-mask-image: none) {
  .sc-image.is-style-circle-mask img {
    border-radius: 0;
    -webkit-mask-image: url('data:image\/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http:\/\/www.w3.org\/2000\/svg"><circle cx="50" cy="50" r="50"\/><\/svg>');
    mask-image: url('data:image\/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http:\/\/www.w3.org\/2000\/svg"><circle cx="50" cy="50" r="50"\/><\/svg>');
    mask-mode: alpha;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain;
  }
}

.sc-image :where(.has-border-color) {
  border-style: solid;
}

.sc-image :where([style*="border-top-color"]) {
  border-top-style: solid;
}

.sc-image :where([style*="border-right-color"]) {
  border-right-style: solid;
}

.sc-image :where([style*="border-bottom-color"]) {
  border-bottom-style: solid;
}

.sc-image :where([style*="border-left-color"]) {
  border-left-style: solid;
}

.sc-image :where([style*="border-width"]) {
  border-style: solid;
}

.sc-image :where([style*="border-top-width"]) {
  border-top-style: solid;
}

.sc-image :where([style*="border-right-width"]) {
  border-right-style: solid;
}

.sc-image :where([style*="border-bottom-width"]) {
  border-bottom-style: solid;
}

.sc-image :where([style*="border-left-width"]) {
  border-left-style: solid;
}

.sc-image figure {
  margin: 0;
}

.sc-image figcaption {
  color: #555;
  font-size: 13px;
  text-align: center;
}

.is-dark-theme .sc-image figcaption {
  color: hsla(0, 0%, 100%, 0.65);
}

.sc-image {
  margin: 0 0 1em;
}
<\/style > <style id="sc-paragraph-inline-css" > .is-small-text {
  font-size: 0.875em;
}

.is-regular-text {
  font-size: 1em;
}

.is-large-text {
  font-size: 2.25em;
}

.is-larger-text {
  font-size: 3em;
}

.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 8.4em;
  font-style: normal;
  font-weight: 100;
  line-height: 0.68;
  margin: 0.05em 0.1em 0 0;
  text-transform: uppercase;
}

body.rtl .has-drop-cap:not(:focus):first-letter {
  float: none;
  margin-left: 0.1em;
}

p.has-drop-cap.has-background {
  overflow: hidden;
}

p.has-background {
  padding: 1.25em 2.375em;
}

:where(p.has-text-color:not(.has-link-color)) a {
  color: inherit;
}

p {
  font-size: var(--sc--preset--font-size--normal);
  font-weight: 400;
  line-height: var(--sc--custom--typography--line-height--medium);
}

.sc-social-links {
  background: none;
  box-sizing: border-box;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  text-indent: 0;
}

.sc-social-links .sc-social-link a,
.sc-social-links .sc-social-link a:hover {
  border-bottom: 0;
  box-shadow: none;
  text-decoration: none;
}

.sc-social-links .sc-social-link a {
  padding: 0.25em;
}

.sc-social-links .sc-social-link svg {
  height: 1em;
  width: 1em;
}

.sc-social-links .sc-social-link span:not(.screen-reader-text) {
  font-size: 0.65em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.sc-social-links.has-small-icon-size {
  font-size: 16px;
}

.sc-social-links,
.sc-social-links.has-normal-icon-size {
  font-size: 24px;
}

.sc-social-links.has-large-icon-size {
  font-size: 36px;
}

.sc-social-links.has-huge-icon-size {
  font-size: 48px;
}

.sc-social-links.aligncenter {
  display: flex;
  justify-content: center;
}

.sc-social-links.alignright {
  justify-content: flex-end;
}

.sc-social-link {
  border-radius: 9999px;
  display: block;
  height: auto;
  transition: transform 0.1s ease;
}

@media (prefers-reduced-motion: reduce) {
  .sc-social-link {
    transition-delay: 0s;
    transition-duration: 0s;
  }
}

.sc-social-link a {
  align-items: center;
  display: flex;
  line-height: 0;
  transition: transform 0.1s ease;
}

.sc-social-link:hover {
  transform: scale(1.1);
}

.sc-social-links .sc-social-link .sc-social-link-anchor,
.sc-social-links .sc-social-link .sc-social-link-anchor svg,
.sc-social-links
  .sc-social-link
  .sc-social-link-anchor:active,
.sc-social-links .sc-social-link .sc-social-link-anchor:hover,
.sc-social-links
  .sc-social-link
  .sc-social-link-anchor:visited {
  fill: currentColor;
  color: currentColor;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link {
  background-color: #f0f0f0;
  color: #444;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-amazon {
  background-color: #f90;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-bandcamp {
  background-color: #1ea0c3;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-behance {
  background-color: #0757fe;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-codepen {
  background-color: #1e1f26;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-deviantart {
  background-color: #02e49b;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-dribbble {
  background-color: #e94c89;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-dropbox {
  background-color: #4280ff;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-etsy {
  background-color: #f45800;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-facebook {
  background-color: #1778f2;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-fivehundredpx {
  background-color: #000;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-flickr {
  background-color: #0461dd;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-foursquare {
  background-color: #e65678;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-github {
  background-color: #24292d;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-goodreads {
  background-color: #eceadd;
  color: #382110;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-google {
  background-color: #ea4434;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-instagram {
  background-color: #f00075;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-lastfm {
  background-color: #e21b24;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-linkedin {
  background-color: #0d66c2;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-mastodon {
  background-color: #3288d4;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-medium {
  background-color: #02ab6c;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-meetup {
  background-color: #f6405f;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-patreon {
  background-color: #ff424d;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-pinterest {
  background-color: #e60122;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-pocket {
  background-color: #ef4155;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-reddit {
  background-color: #ff4500;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-skype {
  background-color: #0478d7;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-snapchat {
  stroke: #000;
  background-color: #fefc00;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-soundcloud {
  background-color: #ff5600;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-spotify {
  background-color: #1bd760;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-telegram {
  background-color: #2aabee;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-tiktok {
  background-color: #000;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-tumblr {
  background-color: #011835;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-twitch {
  background-color: #6440a4;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-twitter {
  background-color: #1da1f2;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-vimeo {
  background-color: #1eb7ea;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-vk {
  background-color: #4680c2;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-wordpress {
  background-color: #3499cd;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-whatsapp {
  background-color: #25d366;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-yelp {
  background-color: #d32422;
  color: #fff;
}

.sc-social-links:not(.is-style-logos-only) .sc-social-link-youtube {
  background-color: red;
  color: #fff;
}

.sc-social-links.is-style-logos-only .sc-social-link {
  background: none;
}

.sc-social-links.is-style-logos-only .sc-social-link a {
  padding: 0;
}

.sc-social-links.is-style-logos-only .sc-social-link svg {
  height: 1.25em;
  width: 1.25em;
}

.sc-social-links.is-style-logos-only .sc-social-link-amazon {
  color: #f90;
}

.sc-social-links.is-style-logos-only .sc-social-link-bandcamp {
  color: #1ea0c3;
}

.sc-social-links.is-style-logos-only .sc-social-link-behance {
  color: #0757fe;
}

.sc-social-links.is-style-logos-only .sc-social-link-codepen {
  color: #1e1f26;
}

.sc-social-links.is-style-logos-only .sc-social-link-deviantart {
  color: #02e49b;
}

.sc-social-links.is-style-logos-only .sc-social-link-dribbble {
  color: #e94c89;
}

.sc-social-links.is-style-logos-only .sc-social-link-dropbox {
  color: #4280ff;
}

.sc-social-links.is-style-logos-only .sc-social-link-etsy {
  color: #f45800;
}

.sc-social-links.is-style-logos-only .sc-social-link-facebook {
  color: #1778f2;
}

.sc-social-links.is-style-logos-only .sc-social-link-fivehundredpx {
  color: #000;
}

.sc-social-links.is-style-logos-only .sc-social-link-flickr {
  color: #0461dd;
}

.sc-social-links.is-style-logos-only .sc-social-link-foursquare {
  color: #e65678;
}

.sc-social-links.is-style-logos-only .sc-social-link-github {
  color: #24292d;
}

.sc-social-links.is-style-logos-only .sc-social-link-goodreads {
  color: #382110;
}

.sc-social-links.is-style-logos-only .sc-social-link-google {
  color: #ea4434;
}

.sc-social-links.is-style-logos-only .sc-social-link-instagram {
  color: #f00075;
}

.sc-social-links.is-style-logos-only .sc-social-link-lastfm {
  color: #e21b24;
}

.sc-social-links.is-style-logos-only .sc-social-link-linkedin {
  color: #0d66c2;
}

.sc-social-links.is-style-logos-only .sc-social-link-mastodon {
  color: #3288d4;
}

.sc-social-links.is-style-logos-only .sc-social-link-medium {
  color: #02ab6c;
}

.sc-social-links.is-style-logos-only .sc-social-link-meetup {
  color: #f6405f;
}

.sc-social-links.is-style-logos-only .sc-social-link-patreon {
  color: #ff424d;
}

.sc-social-links.is-style-logos-only .sc-social-link-pinterest {
  color: #e60122;
}

.sc-social-links.is-style-logos-only .sc-social-link-pocket {
  color: #ef4155;
}

.sc-social-links.is-style-logos-only .sc-social-link-reddit {
  color: #ff4500;
}

.sc-social-links.is-style-logos-only .sc-social-link-skype {
  color: #0478d7;
}

.sc-social-links.is-style-logos-only .sc-social-link-snapchat {
  stroke: #000;
  color: #fff;
}

.sc-social-links.is-style-logos-only .sc-social-link-soundcloud {
  color: #ff5600;
}

.sc-social-links.is-style-logos-only .sc-social-link-spotify {
  color: #1bd760;
}

.sc-social-links.is-style-logos-only .sc-social-link-telegram {
  color: #2aabee;
}

.sc-social-links.is-style-logos-only .sc-social-link-tiktok {
  color: #000;
}

.sc-social-links.is-style-logos-only .sc-social-link-tumblr {
  color: #011835;
}

.sc-social-links.is-style-logos-only .sc-social-link-twitch {
  color: #6440a4;
}

.sc-social-links.is-style-logos-only .sc-social-link-twitter {
  color: #1da1f2;
}

.sc-social-links.is-style-logos-only .sc-social-link-vimeo {
  color: #1eb7ea;
}

.sc-social-links.is-style-logos-only .sc-social-link-vk {
  color: #4680c2;
}

.sc-social-links.is-style-logos-only .sc-social-link-whatsapp {
  color: #25d366;
}

.sc-social-links.is-style-logos-only .sc-social-link-wordpress {
  color: #3499cd;
}

.sc-social-links.is-style-logos-only .sc-social-link-yelp {
  color: #d32422;
}

.sc-social-links.is-style-logos-only .sc-social-link-youtube {
  color: red;
}

.sc-social-links.is-style-pill-shape .sc-social-link {
  width: auto;
}

.sc-social-links.is-style-pill-shape .sc-social-link a {
  padding-left: 0.66667em;
  padding-right: 0.66667em;
}

.sc-columns {
  align-items: normal !important;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap !important;
  margin-bottom: 1.75em;
}

@media (min-width: 782px) {
  .sc-columns {
    flex-wrap: nowrap !important;
  }
}

.sc-columns.are-vertically-aligned-top {
  align-items: flex-start;
}

.sc-columns.are-vertically-aligned-center {
  align-items: center;
}

.sc-columns.are-vertically-aligned-bottom {
  align-items: flex-end;
}

@media (max-width: 781px) {
  .sc-columns:not(.is-not-stacked-on-mobile) > .sc-column {
    flex-basis: 100% !important;
  }
}

@media (min-width: 782px) {
  .sc-columns:not(.is-not-stacked-on-mobile) > .sc-column {
    flex-basis: 0;
    flex-grow: 1;
  }

  .sc-columns:not(.is-not-stacked-on-mobile)
    > .sc-column[style*="flex-basis"] {
    flex-grow: 0;
  }
}

.sc-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important;
}

.sc-columns.is-not-stacked-on-mobile > .sc-column {
  flex-basis: 0;
  flex-grow: 1;
}

.sc-columns.is-not-stacked-on-mobile
  > .sc-column[style*="flex-basis"] {
  flex-grow: 0;
}

:where(.sc-columns.has-background) {
  padding: 1.25em 2.375em;
}

.sc-column {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word;
}

.sc-column.is-vertically-aligned-top {
  align-self: flex-start;
}

.sc-column.is-vertically-aligned-center {
  align-self: center;
}

.sc-column.is-vertically-aligned-bottom {
  align-self: flex-end;
}

.sc-column.is-vertically-aligned-bottom,
.sc-column.is-vertically-aligned-center,
.sc-column.is-vertically-aligned-top {
  width: 100%;
}
.sc-site-title a {
  color: inherit;
}

.sc-site-title {
  color: var(--sc--preset--color--black);
  font-size: var(--sc--preset--font-size--medium);
  font-weight: 800;
  line-height: var(--sc--custom--typography--line-height--small);
  text-transform: capitalize;
}

.sc-site-title a:where(:not(.sc-element-button)) {
  color: var(--sc--preset--color--white);
}

.sc-site-title a:where(:not(.sc-element-button)):hover {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-site-title a:where(:not(.sc-element-button)):focus {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-site-title a:where(:not(.sc-element-button)):active {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-site-tagline {
  color: var(--sc--preset--color--black);
  font-size: var(--sc--preset--font-size--small);
  line-height: var(--sc--custom--typography--line-height--medium);
}

.sc-navigation .sc-navigation-item__label {
  overflow-wrap: break-word;
  word-break: normal;
}

.sc-navigation .sc-navigation-item__description {
  display: none;
}

.sc-navigation {
  color: var(--sc--preset--color--black);
}

.sc-navigation a:where(:not(.sc-element-button)) {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
  font-family: var(--sc--preset--font-family--roboto);
  font-size: var(--sc--preset--font-size--very-small);
  font-weight: 400;
  line-height: var(--sc--custom--typography--line-height--tiny);
  text-transform: capitalize;
}

.sc-navigation a:where(:not(.sc-element-button)):hover {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-navigation a:where(:not(.sc-element-button)):focus {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-navigation a:where(:not(.sc-element-button)):active {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-template-part.has-background {
  margin-bottom: 0;
  margin-top: 0;
  padding: 1.25em 2.375em;
}

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
  padding: 1.25em 2.375em;
}

:root {
  --sc-admin-theme-color: #007cba;
  --sc-admin-theme-color--rgb: 0, 124, 186;
  --sc-admin-theme-color-darker-10: #006ba1;
  --sc-admin-theme-color-darker-10--rgb: 0, 107, 161;
  --sc-admin-theme-color-darker-20: #005a87;
  --sc-admin-theme-color-darker-20--rgb: 0, 90, 135;
  --sc-admin-border-width-focus: 2px;
  --sc-synced-color: #7a00df;
  --sc-synced-color--rgb: 122, 0, 223;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  :root {
    --sc-admin-border-width-focus: 1.5px;
  }
}

.sc-element-button {
  cursor: pointer;
}

:root {
  --sc--preset--font-size--normal: 16px;
  --sc--preset--font-size--huge: 42px;
}

:root .has-very-light-gray-background-color {
  background-color: #eee;
}

:root .has-very-dark-gray-background-color {
  background-color: #313131;
}

:root .has-very-light-gray-color {
  color: #eee;
}

:root .has-very-dark-gray-color {
  color: #313131;
}

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(135deg, #00d084, #0693e3);
}

:root .has-purple-crush-gradient-background {
  background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe);
}

:root .has-hazy-dawn-gradient-background {
  background: linear-gradient(135deg, #faaca8, #dad0ec);
}

:root .has-subdued-olive-gradient-background {
  background: linear-gradient(135deg, #fafae1, #67a671);
}

:root .has-atomic-cream-gradient-background {
  background: linear-gradient(135deg, #fdd79a, #004a59);
}

:root .has-nightshade-gradient-background {
  background: linear-gradient(135deg, #330968, #31cdcf);
}

:root .has-midnight-gradient-background {
  background: linear-gradient(135deg, #020381, #2874fc);
}

.has-regular-font-size {
  font-size: 1em;
}

.has-larger-font-size {
  font-size: 2.625em;
}

.has-normal-font-size {
  font-size: var(--sc--preset--font-size--normal);
}

.has-huge-font-size {
  font-size: var(--sc--preset--font-size--huge);
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-left {
  text-align: left;
}

.has-text-align-right {
  text-align: right;
}

#end-resizable-editor-section {
  display: none;
}

.aligncenter {
  clear: both;
}

.items-justified-left {
  justify-content: flex-start;
}

.items-justified-center {
  justify-content: center;
}

.items-justified-right {
  justify-content: flex-end;
}

.items-justified-space-between {
  justify-content: space-between;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal !important;
  border: 0;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.screen-reader-text:focus {
  clip: auto !important;
  background-color: #ddd;
  -webkit-clip-path: none;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

html :where(.has-border-color) {
  border-style: solid;
}

html :where([style*="border-top-color"]) {
  border-top-style: solid;
}

html :where([style*="border-right-color"]) {
  border-right-style: solid;
}

html :where([style*="border-bottom-color"]) {
  border-bottom-style: solid;
}

html :where([style*="border-left-color"]) {
  border-left-style: solid;
}

html :where([style*="border-width"]) {
  border-style: solid;
}

html :where([style*="border-top-width"]) {
  border-top-style: solid;
}

html :where([style*="border-right-width"]) {
  border-right-style: solid;
}

html :where([style*="border-bottom-width"]) {
  border-bottom-style: solid;
}

html :where([style*="border-left-width"]) {
  border-left-style: solid;
}

html :where(img[class*="sc-image-"]) {
  height: 300px;
  min-width: 207px;
  max-width: 100%;
}

figure {
  margin: 0 0 1em;
}

html :where(.is-position-sticky) {
  --sc-admin--admin-bar--position-offset: var(
    --sc-admin--admin-bar--height,
    0px
  );
}

@media screen and (max-width: 600px) {
  html :where(.is-position-sticky) {
    --sc-admin--admin-bar--position-offset: 0px;
  }
}

body {
  --sc--preset--color--black: #000000;
  --sc--preset--color--cyan-bluish-gray: #abb8c3;
  --sc--preset--color--white: #ffffff;
  --sc--preset--color--pale-pink: #f78da7;
  --sc--preset--color--vivid-red: #cf2e2e;
  --sc--preset--color--luminous-vivid-orange: #ff6900;
  --sc--preset--color--luminous-vivid-amber: #fcb900;
  --sc--preset--color--light-green-cyan: #7bdcb5;
  --sc--preset--color--vivid-green-cyan: #00d084;
  --sc--preset--color--pale-cyan-blue: #8ed1fc;
  --sc--preset--color--vivid-cyan-blue: #0693e3;
  --sc--preset--color--vivid-purple: #9b51e0;
  --sc--preset--color--sc-fse-soft-voilet: #9d69b1;
  --sc--preset--color--sc-fse-light-grayish: #efeae4;
  --sc--preset--color--sc-fse-slightly-desaturated-blue: #84a7c7;
  --sc--preset--color--sc-fse-electric-blue: #7df9ff;
  --sc--preset--color--sc-fse-lightblue: #add8e6;
  --sc--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --sc--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --sc--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --sc--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --sc--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --sc--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --sc--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --sc--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --sc--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --sc--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --sc--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --sc--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --sc--preset--duotone--dark-grayscale: url("#sc-duotone-dark-grayscale");
  --sc--preset--duotone--grayscale: url("#sc-duotone-grayscale");
  --sc--preset--duotone--purple-yellow: url("#sc-duotone-purple-yellow");
  --sc--preset--duotone--blue-red: url("#sc-duotone-blue-red");
  --sc--preset--duotone--midnight: url("#sc-duotone-midnight");
  --sc--preset--duotone--magenta-yellow: url("#sc-duotone-magenta-yellow");
  --sc--preset--duotone--purple-green: url("#sc-duotone-purple-green");
  --sc--preset--duotone--blue-orange: url("#sc-duotone-blue-orange");
  --sc--preset--font-size--small: 13px;
  --sc--preset--font-size--medium: 28px;
  --sc--preset--font-size--large: 37px;
  --sc--preset--font-size--x-large: 65px;
  --sc--preset--font-size--very-small: 14px;
  --sc--preset--font-size--normal: 16px;
  --sc--preset--font-size--semi-medium: 22px;
  --sc--preset--font-family--roboto: "Roboto", sans-serif;
  --sc--preset--font-family--nunito: "Nunito", sans-serif;
  --sc--preset--font-family--literata: "Literata", serif;
  --sc--preset--font-family--adamina: "Adamina", serif;
  --sc--preset--spacing--20: 0.44rem;
  --sc--preset--spacing--30: 0.67rem;
  --sc--preset--spacing--40: 1rem;
  --sc--preset--spacing--50: 1.5rem;
  --sc--preset--spacing--60: 2.25rem;
  --sc--preset--spacing--70: 3.38rem;
  --sc--preset--spacing--80: 5.06rem;
  --sc--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --sc--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --sc--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --sc--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --sc--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
  --sc--custom--typography--font-size--heading-1: clamp(
    2.113rem,
    1.9252rem + 0.9390000000000001vw,
    3.052rem
  );
  --sc--custom--typography--font-size--heading-2: clamp(
    1.669rem,
    1.5146000000000002rem + 0.7719999999999999vw,
    2.441rem
  );
  --sc--custom--typography--font-size--heading-3: clamp(
    1.35rem,
    1.2294rem + 0.603vw,
    1.953rem
  );
  --sc--custom--typography--font-size--heading-4: clamp(
    1.253rem,
    1.1909999999999998rem + 0.31000000000000005vw,
    1.563rem
  );
  --sc--custom--typography--font-size--heading-5: clamp(
    1.063rem,
    1.0255999999999998rem + 0.18700000000000006vw,
    1.25rem
  );
  --sc--custom--typography--font-size--heading-6: clamp(
    1.063rem,
    1.0255999999999998rem + 0.18700000000000006vw,
    1.25rem
  );
  --sc--custom--typography--font-size--post-title: clamp(28px, 1.88vw, 36px);
  --sc--custom--typography--line-height--tiny: 1.2;
  --sc--custom--typography--line-height--small: 1.3;
  --sc--custom--typography--line-height--medium: 1.5;
  --sc--custom--typography--line-height--normal: 1.7;
}

body {
  margin: 0;
  --sc--style--global--content-size: 800px;
  --sc--style--global--wide-size: 1183px;
}

.sc-site-blocks > .alignleft {
  float: left;
  margin-right: 2em;
}

.sc-site-blocks > .alignright {
  float: right;
  margin-left: 2em;
}

.sc-site-blocks > .aligncenter {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.sc-site-blocks > * {
  margin-block-start: 0;
  margin-block-end: 0;
}

.sc-site-blocks > * + * {
  margin-block-start: 24px;
}

body {
  --sc--style--block-gap: 24px;
}

body .is-layout-flow > * {
  margin-block-start: 0;
  margin-block-end: 0;
}

body .is-layout-flow > * + * {
  margin-block-start: 24px;
  margin-block-end: 0;
}

body .is-layout-constrained > * {
  margin-block-start: 0;
  margin-block-end: 0;
}

body .is-layout-constrained > * + * {
  margin-block-start: 24px;
  margin-block-end: 0;
}

body .is-layout-flex {
  gap: 24px;
}

body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body
  .is-layout-constrained
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--sc--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignwide {
  max-width: var(--sc--style--global--wide-size);
}

body .is-layout-flex {
  display: flex;
}

body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}

body .is-layout-flex > * {
  margin: 0;
}

body {
  background-color: var(--sc--preset--color--white);
  color: var(--sc--preset--color--black);
  font-family: var(--sc--preset--font-family--roboto);
  line-height: var(--sc--custom--typography--line-height--tiny);
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

a:where(:not(.sc-element-button)) {
  color: var(--sc--preset--color--black);
  text-decoration: none;
}

a:where(:not(.sc-element-button)):hover {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

a:where(:not(.sc-element-button)):focus {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

a:where(:not(.sc-element-button)):active {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

h1 {
  font-family: var(--sc--preset--font-family--literata);
  font-size: var(--sc--custom--typography--font-size--heading-1);
  font-weight: 700;
  line-height: var(--sc--custom--typography--line-height--tiny);
}

h2 {
  font-family: var(--sc--preset--font-family--literata);
  font-size: var(--sc--custom--typography--font-size--heading-2);
  font-weight: 600;
  line-height: var(--sc--custom--typography--line-height--small);
}

h3 {
  font-family: var(--sc--preset--font-family--literata);
  font-size: var(--sc--custom--typography--font-size--heading-3);
  font-weight: 500;
  line-height: var(--sc--custom--typography--line-height--small);
}

h4 {
  font-family: var(--sc--preset--font-family--literata);
  font-size: var(--sc--custom--typography--font-size--heading-4);
  font-weight: 500;
  line-height: var(--sc--custom--typography--line-height--small);
}

h5 {
  font-family: var(--sc--preset--font-family--literata);
  font-size: var(--sc--custom--typography--font-size--heading-5);
  font-weight: 500;
  line-height: var(--sc--custom--typography--line-height--small);
}

h6 {
  font-family: var(--sc--preset--font-family--literata);
  font-size: var(--sc--custom--typography--font-size--heading-6);
  font-weight: 400;
  line-height: var(--sc--custom--typography--line-height--small);
}

.sc-element-button,
.sc-button__link {
  background-color: #32373c;
  border-width: 0;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: calc(0.667em + 2px) calc(1.333em + 2px);
  text-decoration: none;
}

.has-black-color {
  color: var(--sc--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
  color: var(--sc--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
  color: var(--sc--preset--color--white) !important;
}

.has-pale-pink-color {
  color: var(--sc--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
  color: var(--sc--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
  color: var(--sc--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
  color: var(--sc--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
  color: var(--sc--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
  color: var(--sc--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
  color: var(--sc--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
  color: var(--sc--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
  color: var(--sc--preset--color--vivid-purple) !important;
}

.has-sc-fse-soft-voilet-color {
  color: var(--sc--preset--color--sc-fse-soft-voilet) !important;
}

.has-sc-fse-light-grayish-color {
  color: var(--sc--preset--color--sc-fse-light-grayish) !important;
}

.has-sc-fse-slightly-desaturated-blue-color {
  color: var(
    --sc--preset--color--sc-fse-slightly-desaturated-blue
  ) !important;
}

.has-sc-fse-electric-blue-color {
  color: var(--sc--preset--color--sc-fse-electric-blue) !important;
}

.has-sc-fse-lightblue-color {
  color: var(--sc--preset--color--sc-fse-lightblue) !important;
}

.has-black-background-color {
  background-color: var(--sc--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
  background-color: var(--sc--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
  background-color: var(--sc--preset--color--white) !important;
}

.has-pale-pink-background-color {
  background-color: var(--sc--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
  background-color: var(--sc--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
  background-color: var(--sc--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
  background-color: var(--sc--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
  background-color: var(--sc--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
  background-color: var(--sc--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
  background-color: var(--sc--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
  background-color: var(--sc--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
  background-color: var(--sc--preset--color--vivid-purple) !important;
}

.has-sc-fse-soft-voilet-background-color {
  background-color: var(
    --sc--preset--color--sc-fse-soft-voilet
  ) !important;
}

.has-sc-fse-light-grayish-background-color {
  background-color: var(
    --sc--preset--color--sc-fse-light-grayish
  ) !important;
}

.has-sc-fse-slightly-desaturated-blue-background-color {
  background-color: var(
    --sc--preset--color--sc-fse-slightly-desaturated-blue
  ) !important;
}

.has-sc-fse-electric-blue-background-color {
  background-color: var(
    --sc--preset--color--sc-fse-electric-blue
  ) !important;
}

.has-sc-fse-lightblue-background-color {
  background-color: var(
    --sc--preset--color--sc-fse-lightblue
  ) !important;
}

.has-black-border-color {
  border-color: var(--sc--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
  border-color: var(--sc--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
  border-color: var(--sc--preset--color--white) !important;
}

.has-pale-pink-border-color {
  border-color: var(--sc--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
  border-color: var(--sc--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
  border-color: var(--sc--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
  border-color: var(--sc--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
  border-color: var(--sc--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
  border-color: var(--sc--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
  border-color: var(--sc--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
  border-color: var(--sc--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
  border-color: var(--sc--preset--color--vivid-purple) !important;
}

.has-sc-fse-soft-voilet-border-color {
  border-color: var(--sc--preset--color--sc-fse-soft-voilet) !important;
}

.has-sc-fse-light-grayish-border-color {
  border-color: var(
    --sc--preset--color--sc-fse-light-grayish
  ) !important;
}

.has-sc-fse-slightly-desaturated-blue-border-color {
  border-color: var(
    --sc--preset--color--sc-fse-slightly-desaturated-blue
  ) !important;
}

.has-sc-fse-electric-blue-border-color {
  border-color: var(
    --sc--preset--color--sc-fse-electric-blue
  ) !important;
}

.has-sc-fse-lightblue-border-color {
  border-color: var(--sc--preset--color--sc-fse-lightblue) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --sc--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --sc--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --sc--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --sc--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --sc--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
  background: var(--sc--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
  background: var(--sc--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
  background: var(--sc--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
  background: var(--sc--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
  background: var(--sc--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
  background: var(--sc--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
  background: var(--sc--preset--gradient--midnight) !important;
}

.has-small-font-size {
  font-size: var(--sc--preset--font-size--small) !important;
}

.has-medium-font-size {
  font-size: var(--sc--preset--font-size--medium) !important;
}

.has-large-font-size {
  font-size: var(--sc--preset--font-size--large) !important;
}

.has-x-large-font-size {
  font-size: var(--sc--preset--font-size--x-large) !important;
}

.has-very-small-font-size {
  font-size: var(--sc--preset--font-size--very-small) !important;
}

.has-normal-font-size {
  font-size: var(--sc--preset--font-size--normal) !important;
}

.has-semi-medium-font-size {
  font-size: var(--sc--preset--font-size--semi-medium) !important;
}

.has-roboto-font-family {
  font-family: var(--sc--preset--font-family--roboto) !important;
}

.has-nunito-font-family {
  font-family: var(--sc--preset--font-family--nunito) !important;
}

.has-literata-font-family {
  font-family: var(--sc--preset--font-family--literata) !important;
}

.has-adamina-font-family {
  font-family: var(--sc--preset--font-family--adamina) !important;
}

.sc-container-1.sc-container-1 {
  flex-wrap: nowrap;
  gap: 4px;
}

.sc-container-3.sc-container-3 {
  flex-wrap: nowrap;
  gap: 4px;
  justify-content: center;
}

.sc-container-4.sc-container-4 {
  gap: var(--sc--preset--spacing--40);
}

.sc-container-6.sc-container-6 {
  gap: var(--sc--preset--spacing--30) var(--sc--preset--spacing--30);
  justify-content: flex-end;
}

.sc-container-9.sc-container-9 {
  flex-wrap: nowrap;
  gap: var(--sc--preset--spacing--40) var(--sc--preset--spacing--40);
}

.sc-elements-bb570db379e02705e783021a0228c85d a {
  color: #8a35da;
}

.sc-container-13.sc-container-13 {
  gap: var(--sc--preset--spacing--50);
  justify-content: flex-end;
}

.sc-container-16.sc-container-16 {
  flex-wrap: nowrap;
  gap: 0px 0px;
}

.sc-container-39 {
  flex-wrap: nowrap;
  gap: var(--sc--preset--spacing--50) var(--sc--preset--spacing--50);
  border-bottom-color: #ffffff6e;
  border-bottom-width: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 15px;
  padding-bottom: 25px;
  border-bottom-style: solid;
}

.sc-elements-cb6b8228178ddadbc6a9c6a2a0fc3741 a {
  color: var(--sc--preset--color--sc-fse-soft-voilet);
}

.sc-elements-d3820ed78ded91ff8a882ca6fb342adf a,
.sc-elements-b9e99a894c6d9634cfb803d5c0188fa0 a,
.sc-elements-6dea29beef0d30295c6eb1f825ee87d0 a,
.sc-elements-d1172123316a5b46dae69d335ece98af a,
.sc-elements-f453211d3490569ed40cf440564f8f57 a,
.sc-elements-d0c08c1aca19dcea788645e04849e598 a,
.sc-elements-1dfde89f29698fa9db6355618dad93a3 a,
.sc-elements-33c78fbcbf0006350ea048e08036f685 a,
.sc-elements-66d13e2ac35aeaae9fe5b5c7b73993f2 a,
.sc-elements-2f9c2c041498fb160f4a7e107c10fcb0 a,
.sc-elements-26c67be7a8c66618759754fc99ed53bf a {
  color: var(--sc--preset--color--white);
}

.sc-container-5.sc-container-5 > *,
.sc-container-5.sc-container-5.sc-container-5.sc-container-5 > * + *,
.sc-container-7.sc-container-7 > *,
.sc-container-8.sc-container-8 > *,
.sc-container-10.sc-container-10 > *,
.sc-container-11.sc-container-11 > *,
.sc-container-14.sc-container-14 > *,
.sc-container-15.sc-container-15 > *,
.sc-container-17.sc-container-17 > *,
.sc-container-17.sc-container-17.sc-container-17.sc-container-17 > * + *,
.sc-container-18.sc-container-18 > *,
.sc-container-45.sc-container-45 > *,
.sc-container-45.sc-container-45.sc-container-45.sc-container-45 > * + * {
  margin-block-start: 0;
  margin-block-end: 0;
}

.sc-container-7.sc-container-7
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)),
.sc-container-14.sc-container-14
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  margin-right: 0 !important;
}

.sc-container-7.sc-container-7.sc-container-7.sc-container-7 > * + *,
.sc-container-8.sc-container-8.sc-container-8.sc-container-8 > * + *,
.sc-container-10.sc-container-10.sc-container-10.sc-container-10 > * + *,
.sc-container-11.sc-container-11.sc-container-11.sc-container-11 > * + *,
.sc-container-14.sc-container-14.sc-container-14.sc-container-14 > * + *,
.sc-container-15.sc-container-15.sc-container-15.sc-container-15 > * + *,
.sc-container-18.sc-container-18.sc-container-18.sc-container-18 > * + * {
  margin-block-start: 0px;
  margin-block-end: 0;
}

.sc-container-21.sc-container-21,
.sc-container-25.sc-container-25,
.sc-container-30.sc-container-30,
.sc-container-41.sc-container-41,
.sc-container-43.sc-container-43 {
  flex-wrap: nowrap;
}

.sc-cover
  .sc-cover__background.has-background-dim.has-background-dim-60,
.sc-cover
  .sc-cover__gradient-background.has-background-dim.has-background-dim-60,
.sc-cover-image
  .sc-cover__background.has-background-dim.has-background-dim-60,
.sc-cover-image
  .sc-cover__gradient-background.has-background-dim.has-background-dim-60 {
  opacity: 0.6;
}

.sc-cover .sc-cover__background,
.sc-cover .sc-cover__gradient-background,
.sc-cover-image .sc-cover__background,
.sc-cover-image .sc-cover__gradient-background,
.sc-cover-image.has-background-dim:not(.has-background-gradient):before,
.sc-cover.has-background-dim:not(.has-background-gradient):before {
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.sc-cover,
.sc-cover-image {
  align-items: center;
  background-position: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 430px;
  padding: 1em;
  position: relative;
}

.sc-site-blocks > * + * {
  margin-block-start: 24px;
}
.sc-site-blocks > * {
  margin-block-start: 0;
  margin-block-end: 0;
}

.sc-cover .sc-cover__image-background,
.sc-cover video.sc-cover__video-background,
.sc-cover-image .sc-cover__image-background,
.sc-cover-image video.sc-cover__video-background {
  border: none;
  bottom: 0;
  box-shadow: none;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  -o-object-fit: cover;
  object-fit: cover;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.sc-cover__image-background,
.sc-cover__video-background {
  z-index: 0;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.sc-cover .sc-cover__inner-container,
.sc-cover-image .sc-cover__inner-container {
  color: #fff;
  width: 100%;
  z-index: 1;
}

.sc-site-blocks > * + * {
  margin-top: 0;
}

.sc-search__button:hover,
.sc-file .sc-file__button:hover,
.sc-button__link:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .is-layout-flex.sc-container-5.sc-group {
    justify-content: center;
  }
  .is-layout-flow.sc-container-9.sc-column.is-vertically-aligned-center {
    justify-content: center;
    display: flex;
  }
}


.is-layout-constrained.sc-container-18.sc-group.alignfull {
  box-shadow: 0px 4px 12px 0px #ebe7e7;
}


button.sc-navigation__responsive-container-open {
  border: 1px solid #727272;
}


.sc-search__button {
  margin-left: 0;
}

span.sc-calendar-nav-prev a {
  text-decoration: none;
}

div.scroll-to-top {
  opacity: 0;
  width: 50px;
  right: 30px;
  height: 50px;
  bottom: 30px;
  z-index: 200;
  position: fixed;
  text-align: center;
  visibility: hidden;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease-in;
  background-color: #9b51e0;
}

div.scroll-to-top::after {
  display: flex;
  color: white;
  font-size: 36px;
  content: "\f106";
  align-items: center;
  justify-content: center;
  font-family: FontAwesome;
}

div.scroll-to-top:hover {
  cursor: pointer;
  background-color: rgb(126, 126, 126);
}

div.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
}

.sc-latest-posts.sc-latest-posts__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sc-latest-posts__post-author {
  float: left;
  margin-inline-end: 15px;
}
.sc-latest-posts.sc-latest-posts__list a,
.sc-latest-posts__post-title {
  display: block;
  margin-bottom: 5px;
}
.sc-latest-posts__post-date::after {
  clear: both;
}
.sc-navigation {
  --navigation-layout-justification-setting: flex-start;
  --navigation-layout-direction: row;
  --navigation-layout-wrap: wrap;
  --navigation-layout-justify: flex-start;
  --navigation-layout-align: center;
  position: relative;
}
.sc-navigation ul {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  padding-left: 0;
}
.sc-navigation ul,
.sc-navigation ul li {
  list-style: none;
  padding: 0;
  flex-flow: nowrap;
}
.sc-navigation .sc-navigation-item {
  align-items: center;
  display: flex;
  position: relative;
  white-space: nowrap;
}
.sc-navigation
  .sc-navigation-item
  .sc-navigation__submenu-container:empty {
  display: none;
}
.sc-navigation .sc-navigation-item__content {
  display: block;
  cursor: pointer;
    height: 35px;
    border-radius: 11%;
    padding: 0px 6px !important;
}
.sc-navigation-item__content:hover {
    background-color: var(--sc--preset--color--vivid-purple);
    color: #fff !important;
}
.active-page {
    background-color: var(--sc--preset--color--vivid-purple) !important;
    color: #fff !important;
}
.sc-navigation
  .sc-navigation-item__content.sc-navigation-item__content {
  color: inherit;
}
.sc-navigation.has-text-decoration-underline
  .sc-navigation-item__content,
.sc-navigation.has-text-decoration-underline
  .sc-navigation-item__content:active,
.sc-navigation.has-text-decoration-underline
  .sc-navigation-item__content:focus {
  text-decoration: underline;
}
.sc-navigation.has-text-decoration-line-through
  .sc-navigation-item__content,
.sc-navigation.has-text-decoration-line-through
  .sc-navigation-item__content:active,
.sc-navigation.has-text-decoration-line-through
  .sc-navigation-item__content:focus {
  text-decoration: line-through;
}
.sc-navigation:where(:not([class*="has-text-decoration"])) a {
  text-decoration: none;
}
.sc-navigation:where(:not([class*="has-text-decoration"])) a:active,
.sc-navigation:where(:not([class*="has-text-decoration"])) a:focus {
  text-decoration: none;
}
.sc-navigation .sc-navigation__submenu-icon {
  align-self: center;
  background-color: inherit;
  border: none;
  color: currentColor;
  display: inline-block;
  font-size: inherit;
  height: 0.6em;
  line-height: 0;
  margin-left: 0.25em;
  padding: 0;
  width: 0.6em;
}
.sc-navigation .sc-navigation__submenu-icon svg {
  stroke: currentColor;
  display: inline-block;
  height: inherit;
  margin-top: 0.075em;
  width: inherit;
}
.sc-navigation.is-vertical {
  --navigation-layout-direction: column;
  --navigation-layout-justify: initial;
  --navigation-layout-align: flex-start;
}
.sc-navigation.no-wrap {
  --navigation-layout-wrap: nowrap;
}
.sc-navigation.items-justified-center {
  --navigation-layout-justification-setting: center;
  --navigation-layout-justify: center;
}
.sc-navigation.items-justified-center.is-vertical {
  --navigation-layout-align: center;
}
.sc-navigation.items-justified-right {
  --navigation-layout-justification-setting: flex-end;
  --navigation-layout-justify: flex-end;
}
.sc-navigation.items-justified-right.is-vertical {
  --navigation-layout-align: flex-end;
}
.sc-navigation.items-justified-space-between {
  --navigation-layout-justification-setting: space-between;
  --navigation-layout-justify: space-between;
}
.sc-navigation .has-child .sc-navigation__submenu-container {
  align-items: normal;
  background-color: inherit;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: opacity 0.1s linear;
  visibility: hidden;
  width: 0;
  z-index: 2;
}
.sc-navigation
  .has-child
  .sc-navigation__submenu-container
  > .sc-navigation-item
  > .sc-navigation-item__content {
  display: flex;
  flex-grow: 1;
}
.sc-navigation
  .has-child
  .sc-navigation__submenu-container
  > .sc-navigation-item
  > .sc-navigation-item__content
  .sc-navigation__submenu-icon {
  margin-left: auto;
  margin-right: 0;
}
.sc-navigation
  .has-child
  .sc-navigation__submenu-container
  .sc-navigation-item__content {
  margin: 0;
}
@media (min-width: 782px) {
  .sc-navigation
    .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container {
    left: 100%;
    top: -1px;
  }
  .sc-navigation
    .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container:before {
    background: transparent;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    width: 0.5em;
  }
  .sc-navigation
    .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-icon {
    margin-right: 0.25em;
  }
  .sc-navigation
    .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-icon
    svg {
    transform: rotate(-90deg);
  }
}
.sc-navigation
  .has-child:not(.open-on-click):hover
  > .sc-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto;
}
.sc-navigation
  .has-child:not(.open-on-click):not(.open-on-hover-click):focus-within
  > .sc-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto;
}
.sc-navigation
  .has-child
  .sc-navigation-submenu__toggle[aria-expanded="true"]
  ~ .sc-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto;
}
.sc-navigation.has-background
  .has-child
  .sc-navigation__submenu-container {
  left: 0;
  top: 100%;
}
@media (min-width: 782px) {
  .sc-navigation.has-background
    .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container {
    left: 100%;
    top: 0;
  }
}
.sc-navigation-submenu {
  display: flex;
  position: relative;
}
.sc-navigation-submenu .sc-navigation__submenu-icon svg {
  stroke: currentColor;
}
button.sc-navigation-item__content {
  background-color: transparent;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: left;
  text-transform: inherit;
}
.sc-navigation-submenu__toggle {
  cursor: pointer;
}
.sc-navigation-item.open-on-click .sc-navigation-submenu__toggle {
  padding-right: 0.85em;
}
.sc-navigation-item.open-on-click
  .sc-navigation-submenu__toggle
  + .sc-navigation__submenu-icon {
  margin-left: -0.6em;
  pointer-events: none;
}
.sc-navigation .sc-page-list,
.sc-navigation__container,
.sc-navigation__responsive-close,
.sc-navigation__responsive-container,
.sc-navigation__responsive-container-content,
.sc-navigation__responsive-dialog {
  gap: 10px;
}
:where(
    .sc-navigation.has-background
      .sc-navigation-item
      a:not(.sc-element-button)
  ),
:where(
    .sc-navigation.has-background
      .sc-navigation-submenu
      a:not(.sc-element-button)
  ) {
  padding: 0.5em 1em;
}
:where(
    .sc-navigation
      .sc-navigation__submenu-container
      .sc-navigation-item
      a:not(.sc-element-button)
  ),
:where(
    .sc-navigation
      .sc-navigation__submenu-container
      .sc-navigation-submenu
      a:not(.sc-element-button)
  ),
:where(
    .sc-navigation
      .sc-navigation__submenu-container
      .sc-navigation-submenu
      button.sc-navigation-item__content
  ),
:where(
    .sc-navigation
      .sc-navigation__submenu-container
      .sc-pages-list__item
      button.sc-navigation-item__content
  ) {
  padding: 0.5em 1em;
}
.sc-navigation.items-justified-right
  .sc-navigation__container
  .has-child
  .sc-navigation__submenu-container,
.sc-navigation.items-justified-right
  .sc-page-list
  > .has-child
  .sc-navigation__submenu-container,
.sc-navigation.items-justified-space-between
  .sc-page-list
  > .has-child:last-child
  .sc-navigation__submenu-container,
.sc-navigation.items-justified-space-between
  > .sc-navigation__container
  > .has-child:last-child
  .sc-navigation__submenu-container {
  left: auto;
  right: 0;
}
.sc-navigation.items-justified-right
  .sc-navigation__container
  .has-child
  .sc-navigation__submenu-container
  .sc-navigation__submenu-container,
.sc-navigation.items-justified-right
  .sc-page-list
  > .has-child
  .sc-navigation__submenu-container
  .sc-navigation__submenu-container,
.sc-navigation.items-justified-space-between
  .sc-page-list
  > .has-child:last-child
  .sc-navigation__submenu-container
  .sc-navigation__submenu-container,
.sc-navigation.items-justified-space-between
  > .sc-navigation__container
  > .has-child:last-child
  .sc-navigation__submenu-container
  .sc-navigation__submenu-container {
  left: -1px;
  right: -1px;
}
@media (min-width: 782px) {
  .sc-navigation.items-justified-right
    .sc-navigation__container
    .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container,
  .sc-navigation.items-justified-right
    .sc-page-list
    > .has-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container,
  .sc-navigation.items-justified-space-between
    .sc-page-list
    > .has-child:last-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container,
  .sc-navigation.items-justified-space-between
    > .sc-navigation__container
    > .has-child:last-child
    .sc-navigation__submenu-container
    .sc-navigation__submenu-container {
    left: auto;
    right: 100%;
  }
}
.sc-navigation:not(.has-background)
  .sc-navigation__submenu-container {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #000;
}
.sc-navigation__container {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.sc-navigation__container .is-responsive {
  display: none;
}
.sc-navigation__container:only-child,
.sc-page-list:only-child {
  flex-grow: 1;
}
@keyframes overlay-menu__fade-in-animation {
  0% {
    opacity: 0;
    transform: translateY(0.5em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.sc-navigation__responsive-container {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.sc-navigation__responsive-container .sc-navigation-link a {
  color: inherit;
}
.sc-navigation__responsive-container
  .sc-navigation__responsive-container-content {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
}
.sc-navigation__responsive-container:not(.is-menu-open.is-menu-open) {
  background-color: inherit !important;
  color: inherit !important;
}
.sc-navigation__responsive-container.is-menu-open {
  animation: overlay-menu__fade-in-animation 0.1s ease-out;
  animation-fill-mode: forwards;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: var(--sc--style--root--padding-top, 2rem)
    var(--sc--style--root--padding-right, 2rem)
    var(--sc--style--root--padding-bottom, 2rem)
    var(--sc--style--root--padding-left, 2rem);
  z-index: 100000;
}
@media (prefers-reduced-motion: reduce) {
  .sc-navigation__responsive-container.is-menu-open {
    animation-delay: 0s;
    animation-duration: 1ms;
  }
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content {
  align-items: var(--navigation-layout-justification-setting, inherit);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: visible;
  padding-top: calc(2rem + 24px);
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content,
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation__container,
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-page-list {
  justify-content: flex-start;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation__submenu-icon {
  display: none;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .has-child
  .sc-navigation__submenu-container {
  border: none;
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: initial;
  padding-left: 2rem;
  padding-right: 2rem;
  position: static;
  visibility: visible;
  width: auto;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation__container,
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation__submenu-container {
  gap: inherit;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation__submenu-container {
  padding-top: var(--sc--style--block-gap, 2em);
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation-item__content {
  padding: 0;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation-item,
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-navigation__container,
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__responsive-container-content
  .sc-page-list {
  align-items: var(--navigation-layout-justification-setting, initial);
  display: flex;
  flex-direction: column;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation-item,
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation-item
  .sc-navigation__submenu-container,
.sc-navigation__responsive-container.is-menu-open .sc-page-list {
  background: transparent !important;
  color: inherit !important;
}
.sc-navigation__responsive-container.is-menu-open
  .sc-navigation__submenu-container.sc-navigation__submenu-container.sc-navigation__submenu-container.sc-navigation__submenu-container {
  left: auto;
  right: auto;
}
@media (min-width: 700px) {
  .sc-navigation__responsive-container:not(.hidden-by-default):not(
      .is-menu-open
    ) {
    background-color: inherit;
    display: block;
    position: relative;
    width: 100%;
    z-index: auto;
  }
  .sc-navigation__responsive-container:not(.hidden-by-default):not(
      .is-menu-open
    )
    .sc-navigation__responsive-container-close {
    display: none;
  }
  .sc-navigation__responsive-container.is-menu-open
    .sc-navigation__submenu-container.sc-navigation__submenu-container.sc-navigation__submenu-container.sc-navigation__submenu-container {
    left: 0;
  }
}
.sc-navigation:not(.has-background)
  .sc-navigation__responsive-container.is-menu-open {
  background-color: #fff;
  color: #000;
}
.sc-navigation__toggle_button_label {
  font-size: 1rem;
  font-weight: 700;
}
.sc-navigation__responsive-container-close,
.sc-navigation__responsive-container-open {
  background: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-transform: inherit;
  vertical-align: middle;
}
.sc-navigation__responsive-container-close svg,
.sc-navigation__responsive-container-open svg {
  fill: currentColor;
  display: block;
  height: 24px;
  pointer-events: none;
  width: 24px;
}
.sc-navigation__responsive-container-open {
  display: flex;
}
.sc-navigation__responsive-container-open.sc-navigation__responsive-container-open.sc-navigation__responsive-container-open {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
@media (min-width: 700px) {
  .sc-navigation__responsive-container-open:not(.always-shown) {
    display: none;
  }
}
.sc-navigation__responsive-container-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.sc-navigation__responsive-container-close.sc-navigation__responsive-container-close.sc-navigation__responsive-container-close {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.sc-navigation__responsive-close {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--sc--style--global--wide-size, 100%);
  width: 100%;
}
.sc-navigation__responsive-close:focus {
  outline: none;
}
.is-menu-open .sc-navigation__responsive-close,
.is-menu-open .sc-navigation__responsive-container-content,
.is-menu-open .sc-navigation__responsive-dialog {
  box-sizing: border-box;
}
.sc-navigation__responsive-dialog {
  position: relative;
}
.has-modal-open
  .admin-bar
  .is-menu-open
  .sc-navigation__responsive-dialog {
  margin-top: 46px;
}
@media (min-width: 782px) {
  .has-modal-open
    .admin-bar
    .is-menu-open
    .sc-navigation__responsive-dialog {
    margin-top: 32px;
  }
}
html.has-modal-open {
  overflow: hidden;
}

footer > div {
    background-color: #001a3a;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

footer{
    position: inherit;
    width: 100vw;
    height: 100px;
}

.footer-fiv-1{
    border-bottom-color:#ffffff6e;border-bottom-width:2px;margin-top:0px;margin-bottom:0px;padding-top:30px;padding-bottom:50px
}
.footer-fiv-2{
    font-size:28px;
}
.purple-grade-bkg{
  background:linear-gradient(135deg,rgb(41,0,83) 0%,#aa62ef 100%);margin-top:0;margin-bottom:0;padding-top:0;padding-right:20px;padding-bottom:0;padding-left:20px;color: #fff;
  padding: 50px 0 75px 0px;
}
.purple-grade-bkg2{
  padding-top:0;padding-right:0;padding-bottom:0;padding-left:0
}
.purple-grade-bkg3{
  flex-basis:100%;
}
.purple-grade-bkg4{
  margin-top:0;margin-bottom:0;padding-top:var(--wp--preset--spacing--70);padding-right:0;padding-bottom:var(--wp--preset--spacing--70);padding-left:0
}

.purple-grade-bkg-main{
  min-height: 310px;
}

.purple-grade-div1{
  padding-top:0;padding-right:20px;padding-bottom:0;padding-left:20px
}
.purple-grade-div1{
  flex-basis:50%
}
.purple-grade-div3{
  margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;font-size:16px;font-style:normal;font-weight:600;letter-spacing:5px;text-transform:uppercase;
  margin-block-start: 0 !important;
}
.purple-grade-div4{
  margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;font-size:34px
}
.purple-grade-div5{
  padding-top:0;padding-right:0;padding-bottom:0;padding-left:0;flex-basis:50%
}
.btn-signup{
    position: absolute;
    font-family: Open Sans,Arial,sans-serif;
    text-transform: none;
    padding: 10px 20px;
    height: 44px;
    min-width: 15px;
    font-size: 16px;
    font-weight: 700;
    user-select: none;
    overflow: visible;
    text-decoration: none;
    transform: none;
    line-height: 14px;
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out,box-shadow .1s ease-in-out;
    cursor: pointer;
    background-color: #e3e7ed;
    color: #000;
    border: 2px solid #d5d6d7;
    border-radius: 30px;
    margin-top: 14px;
}
.btn-signup:hover{
  background-color: #bdc1c6 !important;
}

.css-g5ufxp{
    height: 110px;
    width: 160px;
}
.footer-p{
  margin-top: 6px !important;
}
.footer-p a{
  color: #fff
}

.subscribe-footer section{
  display: contents;
}

.subscribe-footer form {
  display: grid !important;
  max-width: 250px;
}

.subscribe-footer form input {
  margin: 2px 0;
}
.subscribe-footer form button{
    font-family: Open Sans,Arial,sans-serif;
    text-transform: none;
    padding: 9px 20px;
    height: 34px;
    font-size: 14px;
    font-weight: 700;
    -webkit-user-select: none;
    user-select: none;
    overflow: visible;
    text-decoration: none;
    transform: none;
    line-height: 14px;
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out,box-shadow .1s ease-in-out;
    cursor: pointer;
    background-color: #e3e7ed;
    color: #000;
    border: 2px solid #d5d6d7;
    border-radius: 30px;
    margin-top: 3px;
}
.subscribe-footer form button:hover{
  background-color: #bdc1c6 !important;
}

.home-content2{
    min-height: 200px !important;
}

.sc-container-41 button{
  background: transparent;
  border: none;
  color: #fff;
  font-size: var(--sc--preset--font-size--normal);
  font-weight: 400;
  line-height: var(--sc--custom--typography--line-height--medium);
  cursor: pointer;
}

.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 999999999999999;
}

.modal-modal-dialog {
    box-shadow: 0 3px 15px rgba(0,0,0,.12), 0 5px 30px rgba(0,0,0,.24);
    padding: 0;
    position: sticky;
    z-index: 2147483645;
    outline: none;
    border: 0;
    text-align: left;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 777px;
}

.sc-modal {
    box-sizing: border-box!important;
}
.sc-settings {
    display: flex;
    flex: 1 1;
    border: 1px solid rgba(0,0,0,.125);
}
.sc-settings-pane {
    position: relative;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-gap: 1em;
    gap: 1em;
    height: 100%;
    width: 100%;
    background-color: #fff;
}
.sc-modal-pane {
    height: 700px;
    overflow: hidden;
    overflow-y: scroll;
}
.sc-icon-button:not(:last-child) {
    margin-right: 0.25em;
}

.close-modal-dialog {
    position: fixed;
    margin-right: 10px!important;
}
.sc-notification-close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}
.sc-icon-button--icon {
    color: #fff;
    background-color: transparent;
    border: 2px solid transparent;
    fill: #000;
}

.sc-icon-button:not(:last-child) {
    margin-right: 0.25em;
}

.close-modal-dialog {
    position: fixed;
    margin-right: 10px!important;
}
.sc-notification-close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}
.sc-icon-button--icon {
    color: #fff;
    background-color: transparent;
    border: 2px solid transparent;
    fill: #000;
}

.sc-button, .sc-button-outlined, .sc-icon-button, .sc-input-group-button {
    position: relative;
    display: inline-block;
    font-family: Open Sans,Arial,sans-serif;
    text-transform: none;
    padding: 10px;
    height: 44px;
    min-width: 15px;
    font-size: 1.4em;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    text-decoration: none;
    transition: background-color .06s ease-in-out;
    transform: none;
    line-height: 1.4;
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out,box-shadow .1s ease-in-out;
    cursor: pointer;
    background-color: #d5d6d7;
    color: #000;
    border: 2px solid #d5d6d7;
    border-radius: 30px;
    position: absolute;
    background: transparent;
    border: none;
}
.sc-icon-button__icon {
    top: -0.1em;
    right: -0.1em;
    height: 1.3em;
    width: 1.3em;
}
.sc-icon-button:hover{
  background-color: #bdc1c6 !important;
}

.sc-button__icon--close {
    content: url("data:image\\/svg+xml,<svg xmlns='http:\\/\\/www.w3.org\/2000\/svg' xmlns:xlink='http:\\/\\/www.w3.org\\/1999\\/xlink' height='20px' viewBox='0 0 13.827 13.826'>%3Cpath d='M13.83 1.33L12.502 0 6.913 5.588 1.33 0 0 1.33l5.588 5.588L0 12.502l1.33 1.328 5.588-5.588 5.584 5.588 1.328-1.328-5.588-5.588z' fill='%23444f66'\/%3E<\/svg>");
}
.sc-button__icon, .sc-icon-button__icon, .sc-input-group-button__icon {
    display: inline-block;
}
.sc-button__icon, .sc-icon-button__icon, .sc-input-group-button__icon {
    position: relative;
    top: -5px;
    right: -2px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
    margin: 0 0.2em 0 0;
}
.modal-child-container{
  padding:60px;
  height: 580px;
}
.formkit-background{
  background-image: url("\/\/embed.filekitcdn.com\/e\/fHBXQCYQBJz1F8eDkhpZzY\/brSF5R1mzQfotUXaDyxZGY");
  background-position: center center;
  background-size: cover;
  min-height: 200px;
  max-height: 580px;
}
.formkit-header{
  outline: none; white-space: pre-wrap; overflow-wrap: break-word; color: rgb(83, 83, 83); font-size: 19px; font-weight: 700;
  margin: -6px;
}
.formkit-header h2{
  font-size: 32px !important;
}
.formkit-disclaimer{
  outline: none; white-space: pre-wrap; overflow-wrap: break-word; color: #494848; font-size: 13px;
  margin: -10px;
  margin-top: 40px;
}
.text-center{
  text-align: center;
}
.signup-container{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(200px,1fr));
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.formkit-column{
  padding: 40px;
    padding-right: 6px;
  text-align: center;
}
.seva-fields{
    position: relative;
    bottom: -60px;
}
.signup-modal-form form{
    display: grid;
    margin-top: 55px;
}
.signup-modal-form input{
    height: 30px;
    margin-bottom: 20px;
}

.signup-modal-form button{
font-family: Open Sans,Arial,sans-serif;
    text-transform: none;
    padding: 9px 20px;
    height: 34px;
    font-size: 14px;
    font-weight: 700;
    -webkit-user-select: none;
    user-select: none;
    overflow: visible;
    text-decoration: none;
    transform: none;
    line-height: 14px;
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out,box-shadow .1s ease-in-out;
    cursor: pointer;
    background-color: #e3e7ed;
    color: #000;
    border: 2px solid #d5d6d7;
    border-radius: 30px;
    margin-top: 3px;
}
.purple-grade-h4{
  margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 32px;
}
.pre-order-promo{
  max-width: 400px;
}

.formkit-testimonial {
  padding: 30px;
  border: 2px solid #eeeeee;
  margin: 20px 45px;
  display: -webkit-box;
  display: flex;
}
.formkit-testimonial-mark {
  margin-right: 20px;
}
.formkit-testimonial-mark img {
  max-width: 20px;
}

.formkit-testimonial_quote h1{
  font-size: 18px !important;
}
.formkit-testimonial_byline h1{
  font-size: 16px !important;
}

.formkit-testimonial_byline {
    text-transform: uppercase;
    font-family: var(--form-font-family);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    margin: 20px 0 0 0;
}

.formkit-testimonial_quote {
    margin: 0;
    font-style: italic;
    font-weight: 400;
    font-size: 21px;
    line-height: 32px;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  margin-block: 2rem;
  gap: 2rem;
  overflow-y: hidden;
  overflow-x: scroll !important;
  padding: 20px;
  /* background: linear-gradient(135deg,rgb(41,0,83) 0%,rgb(170,98,239) 100%); */
}

.card__header img {
  max-width: 100%;
  display: block;
  object-fit: cover;
  height: 230px;
}

.card {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;
  overflow: hidden;
  box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  background: #ECE9E6;
background: linear-gradient(to right, #FFFFFF, #ECE9E6);

}



.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}


.tag {
  align-self: flex-start;
  padding: .25em .75em;
  border-radius: 1em;
  font-size: .75rem;
}

.tag + .tag {
  margin-left: .5em;
}

.tag-blue {
  background: #56CCF2;
background: linear-gradient(to bottom, #2F80ED, #56CCF2);
  color: #fafafa;
}

.tag-brown {
  background: #D1913C;
background: linear-gradient(to bottom, #FFD194, #D1913C);
  color: #fafafa;
}

.tag-red {
  background: #cb2d3e;
background: linear-gradient(to bottom, #ef473a, #cb2d3e);
  color: #fafafa;
}

.card__body h4 {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: .5rem;
  flex-direction: row;
  align-items: center;
}

.user__image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.user__info > small {
  color: #666;
}
.user__info h5{
  margin: 0;
}

.updates-h2{
  margin-bottom: -32px;
  margin-top: 0px;
  border-top: 5px solid #424242;
  padding-top: 20px;
  font-size: 22px;
  background: #e7e7e7;
}

.card__body h4{
  margin: 0;
}
.card__body p{
  margin: 0;
  font-size: 14px;
}
.success-body{
  margin-top: 0;
}
.disclaimer{
  margin-top: 160px;
  font-size: 12px;
}
.interview-container{
  margin-top: 42px;
  display: flex;
}

.interview-pic{
  height: 200px;
  margin: 20px;
  min-width: 115px;
}

.interview-questions-div{
  padding-right: 20px;
}
.interview-questions-div > p:nth-child(even){
  margin-top: 0;
}
.interview-questions-div > p:nth-child(odd){
  margin-bottom: 0;
}

.continue-reading{
  background: transparent;
  border: none;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
}

.privacy-container h2{
  font-size: 22px;
  margin-bottom: 0;
}
.privacy-container p{
  margin-top: 0;
}
.privacy-main-container{
  padding: 20px;
  height: calc(100vh - 391px);
}

.hoverArea{
  cursor: pointer;
}
.mapContainer{
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.mapContainer img{
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  padding: 25px 50px;
}

.banner {
  margin: 30px 0px;
  margin-top: 20px;
}

.parchment {
  margin: 0 auto;
  background-color: #f5e6c4; /* Background color for the "parchment" */
  padding: 20px;
  border: 2px solid #5c392c; /* Border color for the "parchment" */
  border-radius: 10px;
  max-width: 80%;
  text-align: center;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.stain {
  background-color: #8b4513; /* Stain color (brown) */
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3; /* Adjust the opacity to control the stain's intensity */
  z-index: -1;
  border-radius: 10px;
}

.parchment p {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  font-weight: bold;
}

.main-mapContainer{
  background: #6f577c47;
  padding-top: 1px;
  padding-bottom: 20px;
}

.islands-subtext{
  font-size: 14px;
  font-style: italic;
  font-weight: normal !important;
}

.info-box {
  position: absolute;
  background-color: #9941EC;
  color: white;
  padding: 10px;
  border-radius: 5px;
  pointer-events: none; /* Allows mouse events to pass through the info box */
  user-select: none; /* Prevents text selection inside the info box */
  width: 250px;
  display: none;
}

.map-info-container h1{
  font-size: 16px;
  margin: 0;
}

.map-info-container p{
  font-size: 14px;
  margin: 0;
}

.map-mobile{
  cursor: zoom-in;
}

.map-fullscreen{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  cursor: zoom-out;
  padding: 0px !important;
}

.map-details-all{
  margin-top: 50px;
}

.map-details-collapsable h4{
  border: 1px solid black;
  margin: -5px 18px;
  background: white;
  cursor: pointer;
  padding: 5px;
}

.map-details-collapsable p{
  padding: 0 20px;
}

.map-mobile-view-quote{
  padding: 0 5px !important;
  margin: 10px;
}
.map-mobile-view-quote h1{
  font-size: 15px !important;
}

/* Add additional styles to customize the banner and parchment as needed */


@-webkit-keyframes blink { 
  50% { border-color: yellow; } 
}
@keyframes blink { 
  50% { border-color: yellow; } 
}
.highlight-contact {
   /* padding: 20px; */
  border: 2px solid transparent;
  margin: -2px !important;
}

.highlight-contact {
   animation: blink .5s step-end infinite alternate;
   -webkit-animation: blink .5s step-end infinite alternate;
}

@media (max-width: 1000px){
  .formkit-testimonial_quote {
      font-size: 18px;
      line-height: 26px;
  }
}

@media (max-width: 1000px){
  .formkit-testimonial_byline {
      font-size: 13px;
  }
}

@media (max-width: 1000px){
  .formkit-testimonial {
      padding: 20px 32px;
  }
}

@media (max-width: 450px) {
  .sc-image-14{
    display: none;
  }
  .pre-order-promo{
    display: none;
  }
}
@media (min-width: 600px) {
  .sc-container-39{
    flex-wrap: nowrap !important;
  }
}
@media (max-width: 770px) {
  .modal-modal-dialog{
    width: 700px;
  }
  .formkit-background {
    max-height: 500px;
    position: relative;
    top: 40px;
  }
  .formkit-disclaimer {
    margin: -10px;
    margin-top: 0px;
  }
  .disclaimer{
    margin-top: 100px;
  }
}
@media (max-width: 700px) {
  .modal-modal-dialog{
    width: 600px;
  }
  .formkit-background {
    max-height: 400px;
    position: relative;
    top: 75px;
  }
  .formkit-disclaimer {
    margin: -10px;
    margin-top: -50px;
  }
  .pre-order-promo {
    max-width: 300px;
    height: 228px;
  }
}
@media (max-width: 580px) {
  .modal-modal-dialog{
    width: auto;
  }
  .formkit-background {
    display: none;
  }
  .formkit-disclaimer {
    margin: -10px;
    margin-top: -50px;
  }
  .pre-order-promo {
    height: 170px;
  }
}

